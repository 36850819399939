@page {
  size: A4;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
}

.print-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  page-break-after: always; /* 在新页面开始新内容 */
}
